<template>
	<el-container class="mingchen">
		<el-main>
			<div style="margin-top: -50px;">
				<el-tabs style="width: 360px; margin: 30px auto 0;" type="border-card" @tab-click="changeTab">
					<el-tab-pane label="密码登录">
						<el-form ref="loginForm" label-position="top" style="min-height: 380px" :model="login_form" :rules="rules" @submit.prevent status-icon>
							<el-form-item label="登录账户" prop="account">
								<el-input type="text" v-model="login_form.account" autocomplete="off" placeholder="请输入手机号码 OR 电子邮箱"></el-input>
							</el-form-item>
							<el-form-item label="登录密码" prop="password">
								<el-input type="password" v-model="login_form.password" autocomplete="off" placeholder="请输入登录密码" @keyup.enter.prevent="submitForm('loginForm')"></el-input>
							</el-form-item>
							<el-form-item>
								<el-button type="primary" @click="submitForm('loginForm')">立即登录</el-button>
								<el-button @click="$refs['loginForm'].resetFields();">重置</el-button>
							</el-form-item>
							<el-form-item>
								<div style="line-height: 1.5; font-size: 12px;">
									<p style="color: #333;">Tips：</p>
									<p style="color: #999;">· 若当前企业绑定企业微信，首次登陆请使用手机授权注册</p>
									<p style="color: #999;">· 请不要转借使用他人账户，需要账户请联系管理员开通</p>
								</div>
							</el-form-item>
						</el-form>
					</el-tab-pane>
					<el-tab-pane label="企业微信" name="wochat" v-if="wochat_login">
						<div id="wx_qrcode" style="margin: 0 auto;"></div>
					</el-tab-pane>
					<el-tab-pane label="钉钉" name="dingtalk" v-if="dingtalk_login">
						<div id="dd_qrcode" style="margin: 0 auto;"></div>
					</el-tab-pane>
				</el-tabs>
			</div>
			<div style="color: #666; font-size: 12px; text-align: center;">CopyRight 2019 - {{year}} {{$config.COMPANY}} 版权所有 <a href="https://beian.miit.gov.cn/">粤ICP备34235444号</a></div>
		</el-main>
	</el-container>
</template>

<style>
	.mingchen {
		height: 100vh;
		padding-top: 20vh;
		background-image: url('~@/assets/login_bg.jpg');
		background-position: top;
		background-size: cover;
		justify-content: flex-end;
	}
	.mingchen .el-main {
		flex: unset;
		width: calc(46vw);
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
</style>

<script>
	import { mapMutations } from 'vuex';
	import * as ww from '@wecom/jssdk';

	export default {
		computed: {
			year () {
				var d = new Date();
				return d.getFullYear();
			},
			is_mobile () {
				return this.$utils.isMobile();
			}
		},
		methods: {
			...mapMutations(['login']),
			changeTab (e) {
				const { redirect_uri } = this.$route.query;
				this.ww_login && this.ww_login.unmount();
				switch (e.$el.id) {
					case 'pane-wochat':
						this.ww_login = ww.createWWLoginPanel({
							el: '#wx_qrcode',
							params: {
								...this.wochat_login,
								state: 'loginWochat',
								panel_size: 'small',
								login_type: 'CorpApp',
								redirect_type: 'callback',
								redirect_uri: redirect_uri ? decodeURIComponent(redirect_uri) : window.location.href
							},
							onLoginSuccess: ({ code }) => {
								this.toLogin({action: 'wochat', ...this.wochat_login, code});
							}
						});
					break;
					case 'pane-dingtalk':
						window.DTFrameLogin(
							{
								id: 'dd_qrcode',
								width: 300,
								height: 300,
							}, {
								...this.dingtalk_login,
								scope: 'openid',
								prompt: 'consent',
								response_type: 'code',
								redirect_uri: encodeURIComponent(redirect_uri || window.location.href)
							}, async ({ authCode: code }) => {
								this.toLogin({action: 'dingtalk', ...this.dingtalk_login, code});
							}
						);
					break;
				}
			},
			submitForm (ref) {
				this.$refs[ref].validate(async (valid) => {
					if (!valid) return this.$message('请输入登录账户密码！');
					this.toLogin(this.login_form);
				});
			},
			async toLogin (data) {
				const res = await this.$http.post(this.$api.URI_LOGIN, data);
				const { redirect_uri } = this.$route.query;
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message({
					type: 'success',
					duration: 1000,
					message: '登录成功！正在进入系统...',
					onClose: () => {
						this.login(result);
						if (!redirect_uri) return this.$router.push({path: '/'});
						window.location.href = decodeURIComponent(redirect_uri);
					}
				});
			}
		},
		data() {
			return {
				type: true,
				wochat_login: null,
				dingtalk_login: null,
				ww_login: null,
				login_form: {
					account: '',
					password: '',
				},
				rules: {
					account: [{required: true, message: '请输入手机号码 OR 电子邮箱！', trigger: 'blur'}],
					password: [{required: true, message: '请输入登录密码！', trigger: 'blur'}]
				}
			};
		},
		async created () {
			const { host } = window.location;
			const res = await this.$http.post(this.$api.URI_LOGIN, {action: 'getoa', host});
			const { code, result } = res.data;
			if (code) return false;
			if (result.wochat) {
				// 获取企业微信appid 和 agentid;
				this.wochat_login = result.wochat;
			}
			if (result.dingtalk) {
				// 获取钉钉cleint_id;
				this.dingtalk_login = result.dingtalk;
			}
			// console.log(this.$route)
			// var code = this.$route.query.code;
			// this.$message.error(code);
			// code && this.toLogin({ code });
		}
	}
</script>
